"use client";

import * as React from "react";
import { usePathname, useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "@/lib/utils";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
} from "@/components/ui/command";

import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";

import { Button } from "@/components/ui/button";

import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";

import { Input } from "@/components/ui/input";

import { countriesList } from "@/models/countries-list";

import { ScrollArea } from "@/components/ui/scroll-area";
import { MixpanelTracking } from "@/lib/mixpanel-browser-analytics";
import { debugLog } from "@/services/logging";

const countries = [{ label: "All Countries", value: "all" }].concat(
	countriesList.map((country) => {
		return { label: country, value: country.toLowerCase() };
	})
);

const categories = [
	{ label: "All Types", value: "all" },
	{ label: "Promo Videos", value: "promotional" },
	{ label: "Skill Videos", value: "skill" },
	{ label: "Skill Categories", value: "skill category" },
	{ label: "Users", value: "user" },
] as const;

type SearchProps = {
	searchParams?: { [key: string]: string | string[] | undefined };
	searchTitle?: string;
};

export default function Search({ searchParams, searchTitle }: SearchProps) {
	const router = useRouter();
	const pathname = usePathname();
	const [countriesOpen, setCountriesOpen] = React.useState(false);
	const [categoriesOpen, setCategoriesOpen] = React.useState(false);

	debugLog("Search", searchParams);

	let category =
		searchParams === undefined
			? "all"
			: String(searchParams["index"]).toLowerCase();

	category = pathname.includes("products-and-business")
		? "promotional"
		: category;

	category = pathname.includes("skills") ? "skill" : category;

	const defaultValues = {
		query: "",
		country: "all",
		category: category,
	};

	const form = useForm({ defaultValues });

	const convertToUpper = (value: string) => {
		if (value === "promotional" || value === "skill") {
			return value.charAt(0).toUpperCase() + value.slice(1);
		} else {
			return value;
		}
	};

	function onSubmit(values: Record<string, string>) {
		const query = values["query"] ?? "";
		const index = convertToUpper(values["category"]) ?? "";
		const location = values["country"] ?? "";
		MixpanelTracking.getInstance().searchInterest({
			searchQuery: query,
			category: index,
			location,
		});
		router.push(`/search?query=${query}&index=${index}&location=${location}`);
	}

	return (
		<Form {...form}>
			<form
				onSubmit={form.handleSubmit(onSubmit)}
				className="flex flex-col space-y-5">
				<FormField
					control={form.control}
					name="query"
					render={({ field }) => (
						<FormItem>
							<FormLabel
								className={
									searchTitle
										? "font-semibold text-2xl"
										: "font-medium text-lg "
								}>
								{searchTitle ?? "Search your interest"}
							</FormLabel>
							<FormControl>
								<Input
									placeholder="Search"
									{...field}
									className="w-full max-w-[700px] lg:w-[40vw] pl-12 rounded-full bg-[url('/images/icon-search.svg')] bg-no-repeat homeSearchIcon"
								/>
							</FormControl>

							<FormMessage />
						</FormItem>
					)}
				/>

				<div className="flex flex-col gap-6 sm:flex-row">
					<FormField
						control={form.control}
						name="country"
						render={({ field }) => (
							<FormItem className="">
								<Popover
									open={countriesOpen}
									onOpenChange={setCountriesOpen}>
									<PopoverTrigger asChild>
										<FormControl>
											<Button
												variant="outline"
												aria-label="Select Country"
												role="combobox"
												className={cn(
													"w-full justify-between sm:w-[200px]",
													!field.value && "text-muted-foreground"
												)}>
												{field.value
													? countries.find(
															(country) =>
																country.value === field.value
													  )?.label
													: "Select Country"}
												<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
											</Button>
										</FormControl>
									</PopoverTrigger>
									<PopoverContent
										side="bottom"
										className="w-[200px] p-0">
										<Command>
											<CommandInput placeholder="Search Countries..." />
											<CommandEmpty>
												Couldn&apos;t find country.
											</CommandEmpty>
											<CommandGroup>
												<ScrollArea className="h-[200px] p-2">
													{countries.map((country) => (
														<CommandItem
															value={country.value}
															key={country.value}
															onSelect={(value) => {
																form.setValue("country", value);
																setCountriesOpen(false);
															}}>
															<Check
																className={cn(
																	"mr-2 h-4 w-4",
																	country.value === field.value
																		? "opacity-100"
																		: "opacity-0"
																)}
															/>
															{country.label}
														</CommandItem>
													))}
												</ScrollArea>
											</CommandGroup>
										</Command>
									</PopoverContent>
								</Popover>
								<FormDescription />
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="category"
						render={({ field }) => (
							<FormItem className="">
								<Popover
									open={categoriesOpen}
									onOpenChange={setCategoriesOpen}>
									<PopoverTrigger asChild>
										<FormControl>
											<Button
												variant="outline"
												aria-label="Select Category"
												role="combobox"
												className={cn(
													"w-full justify-between sm:w-[200px]",
													!field.value && "text-muted-foreground"
												)}>
												{field.value
													? categories.find(
															(category) =>
																category.value === field.value
													  )?.label
													: "Select Category"}
												<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
											</Button>
										</FormControl>
									</PopoverTrigger>
									<PopoverContent className="w-[200px] p-0">
										<Command>
											<CommandInput placeholder="Search Categories..." />
											<CommandEmpty>
												Couldn&apos;t find category.
											</CommandEmpty>
											<CommandGroup>
												{categories.map((category) => (
													<CommandItem
														value={category.value}
														key={category.value}
														onSelect={(value) => {
															form.setValue("category", value);
															setCategoriesOpen(false);
														}}>
														<Check
															className={cn(
																"mr-2 h-4 w-4",
																category.value === field.value
																	? "opacity-100"
																	: "opacity-0"
															)}
														/>
														{category.label}
													</CommandItem>
												))}
											</CommandGroup>
										</Command>
									</PopoverContent>
								</Popover>
								<FormDescription />
								<FormMessage />
							</FormItem>
						)}
					/>

					<Button
						type="submit"
						className="text-white bg-blue-500 hover:bg-blue-700 font-semibold text-lg hidden sm:flex">
						Search
					</Button>
				</div>
			</form>
		</Form>
	);
}
