"use client";

import { MixpanelTracking } from "@/lib/mixpanel-browser-analytics";
import Link from "next/link";

export default function JoinNowButton() {
	return (
		<Link
			href="/login"
			onClick={() =>
				MixpanelTracking.getInstance().joinNowButtonClicked(
					"'JOIN NOW' button clicked"
				)
			}>
			<button
				aria-label="Join Now"
				className="h-16 w-[80%] bg-[#198754] hover:bg-[#000] hover:scale-105 origin-center transition-all duration-400 text-white shadow-[10px_10px_0px_1px_#5fe385] rounded-md sm:w-[18rem] md:origin-left">
				<strong>JOIN NOW</strong>
			</button>
		</Link>
	);
}
