"use client";

import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import Image from "next/image";

export const HomeCarousel = () => {
	const [emblaRef] = useEmblaCarousel(
		{
			loop: true,
			watchDrag: false,
		},
		[Autoplay({ jump: true, delay: 6000 })]
	);

	return (
		<div className="overflow-hidden" ref={emblaRef}>
			<div className="flex">
				<div className="flex-[0_0_100%]">
					<Image
						src="/images/converted-webp/skills_online_slide4.webp"
						className="w-[900px] h-auto sm:min-w-[160px]"
						alt="Woman learing how to play the guitar"
						width={750}
						height={541}
						sizes="(min-width: 1880px) calc(3.57vw + 793px), (min-width: 1380px) calc(69.17vw - 447px), (min-width: 1040px) calc(5vw + 428px), (min-width: 780px) calc(91.67vw - 235px), calc(100vw - 128px)"
					/>
				</div>
				<div className="flex-[0_0_100%]">
					<Image
						src="/images/converted-webp/skills_online_slide2.webp"
						className="w-[900px] h-auto  sm:min-w-[160px]"
						alt="Man learning construction"
						width={750}
						height={541}
						sizes="(min-width: 1880px) calc(3.57vw + 793px), (min-width: 1380px) calc(69.17vw - 447px), (min-width: 1040px) calc(5vw + 428px), (min-width: 780px) calc(91.67vw - 235px), calc(100vw - 128px)"
					/>
				</div>
				<div className="flex-[0_0_100%]">
					<Image
						src="/images/converted-webp/skills_online_slide3.webp"
						className="w-[900px] h-auto sm:min-w-[160px]"
						alt="Woman sewing"
						width={750}
						height={541}
						sizes="(min-width: 1880px) calc(3.57vw + 793px), (min-width: 1380px) calc(69.17vw - 447px), (min-width: 1040px) calc(5vw + 428px), (min-width: 780px) calc(91.67vw - 235px), calc(100vw - 128px)"
					/>
				</div>
			</div>
		</div>
	);
};
